var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": "Hello User",
      "apiStatusList": []
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Status ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "md": 2
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" To-do ")]), _c('v-list-item-subtitle', [_vm._v(" 400 ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "md": 2
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Completed ")]), _c('v-list-item-subtitle', [_vm._v(" 100 ")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "pl-5",
    attrs: {
      "max-width": "305"
    }
  }, [_c('pie-chart', {
    attrs: {
      "data": _vm.submissionStats,
      "options": _vm.submissionChartOptions
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Problems ")])], 1), _c('v-row', [_c('v-col', [_vm._v(" Solve problems that have been recommended just for you. You can also check out "), _c('a', {
    staticClass: "dashboard_link",
    attrs: {
      "href": "/problems"
    }
  }, [_vm._v(" all problems ")]), _vm._v(". ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [_vm.userContentPreviews ? _c('problem-list', {
    attrs: {
      "problems": _vm.userContentPreviews,
      "autopick": false,
      "showOption": false,
      "hideFooter": true
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Drills Status ")])])], 1), _c('v-row', [_c('v-col', [_vm._v(" Continue where you left off, track your progress on all the active drills you are practicing ")])], 1), _c('v-row', {
    staticClass: "overflow-x-auto"
  }, _vm._l(_vm.companies, function (company) {
    return _c('v-col', {
      key: company.name,
      attrs: {
        "cols": 12,
        "sm": 6,
        "md": 3,
        "lg": 3
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "width": "270",
        "height": "100"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "my-4 ml-4",
      attrs: {
        "cols": "3",
        "size": "imageWidth",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require('./../assets/images/companydrill/' + company.name + '.png')
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate  ",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(company.name) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(company.name) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(company.solved) + " /100 Completed")])], 1)], 1), _c('v-progress-linear', {
      staticClass: "mt-5",
      attrs: {
        "bottom": "",
        "absolute": "",
        "color": "accent",
        "value": company.solved,
        "height": "10"
      }
    })], 1)], 1);
  }), 1), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Skill Insights ")])], 1), _c('v-row', [_c('v-col', [_vm._v(" Stay on the top of your game, track your performance in various topics ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Strengths ")])], 1), _c('v-row', _vm._l(_vm.strengths, function (strength) {
    return _c('v-col', {
      key: strength.name,
      attrs: {
        "cols": 12,
        "sm": 6,
        "md": 3,
        "lg": 3
      }
    }, [_c('v-card', {
      staticClass: "strength",
      attrs: {
        "outlined": "",
        "width": 270,
        "height": 100
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "my-4 ml-4",
      attrs: {
        "size": _vm.imageWidth,
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require('./../assets/images/drill/' + strength.name + '.svg')
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate  ",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(strength.name) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(strength.name) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(strength.solved) + "/350 Completed "), _c('br')])], 1)], 1)])], 1);
  }), 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-5 font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Weaknesses ")])], 1), _c('v-row', _vm._l(_vm.weaknesses, function (weakness) {
    return _c('v-col', {
      key: weakness.name,
      attrs: {
        "cols": 12,
        "sm": 6,
        "md": 3,
        "lg": 3
      }
    }, [_c('v-card', {
      staticClass: "weakness",
      attrs: {
        "id": "next",
        "outlined": "",
        "width": 270,
        "height": 100
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "my-4 ml-4",
      attrs: {
        "size": "imageWidth",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require('./../assets/images/drill/' + weakness.name + '.svg')
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var on = _ref3.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(weakness.name) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(weakness.name) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(weakness.solved) + "/350 Completed "), _c('br')])], 1)], 1)])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }