<template>
<page title="Hello User" :apiStatusList="[]">

	<v-row>
		<v-col :cols="12">
			<v-row>
				<v-col class="font-weight-bold text-truncate text-h5">
				Status
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="6" :sm="4" :md="2">
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>
								To-do
							</v-list-item-title>
							<v-list-item-subtitle>
								400
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
				<v-col :cols="6" :sm="4" :md="2">
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>
								Completed
							</v-list-item-title>
							<v-list-item-subtitle>
								100
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card class="pl-5" max-width="305">
							<pie-chart :data="submissionStats" :options="submissionChartOptions"></pie-chart>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>

	<v-row class="mt-10">
		<v-col class="font-weight-bold text-truncate text-h5">
		Problems
		</v-col>
	</v-row>
	<v-row>
		<v-col>
		Solve problems that have been recommended just for you. You can also check out <a class="dashboard_link" href="/problems"> all problems </a>.
		</v-col>
	</v-row>
	<v-row>
		<v-col :cols="10"> <!-- WHY is this not working? -->
			<problem-list v-if="userContentPreviews" :problems="userContentPreviews" :autopick="false" :showOption="false" :hideFooter="true"></problem-list>
		</v-col>
	</v-row>

	<v-row class="mt-12">
		<v-col>
			<span class="font-weight-bold text-truncate text-h5">
				Drills Status
			</span>
		</v-col>
	</v-row>
	<v-row>
		<v-col>
			Continue where you left off, track your progress on all the active drills you are practicing
		</v-col>
	</v-row>
	<v-row class="overflow-x-auto">
		<v-col :cols="12"  :sm="6" :md="3" :lg="3" v-for="(company) in companies" v-bind:key="company.name">
			<v-card outlined width="270" height="100">
				<div class="d-flex flex-no-wrap">
					<v-avatar cols="3" class="my-4 ml-4" size="imageWidth" tile>
						<v-img :src="require('./../assets/images/companydrill/' + company.name + '.png')"></v-img>
					</v-avatar>
					<div>
						<v-card-title class="text-caption-1">
						<v-tooltip top>
						   <template  v-slot:activator="{ on }">
						     <span v-bind:style="{ width: titleWidth + 'px'  }" class="d-inline-block text-truncate  "  v-on="on">
						       {{company.name}}
						     </span>
			                </template>
						    {{company.name}}
						</v-tooltip>
						</v-card-title>
						<v-card-subtitle> {{company.solved}} /100 Completed</v-card-subtitle>
					</div>
				</div>
				<v-progress-linear bottom absolute color="accent" class="mt-5" :value="company.solved" height="10"></v-progress-linear>
			</v-card>
		</v-col>
	</v-row>

	<v-row class="mt-12">
		<v-col class="font-weight-bold text-truncate text-h5">
				Skill Insights
		</v-col>
	</v-row>
	<v-row>
		<v-col>
			Stay on the top of your game, track your performance in various topics
		</v-col>
	</v-row>
	<v-row>
		<v-col class="font-weight-bold text-truncate text-h5">
				Strengths
		</v-col>
	</v-row>
	<v-row>
		<v-col :cols="12"  :sm="6" :md="3" :lg="3" v-for="(strength) in strengths" v-bind:key="strength.name">
			<v-card outlined class="strength" :width="270" :height="100">
				<div class="d-flex flex-no-wrap">
					<v-avatar class="my-4 ml-4" :size="imageWidth" tile>
						<v-img :src="require('./../assets/images/drill/' + strength.name + '.svg')"></v-img>
					</v-avatar>
					<div>
						<v-card-title class="text-caption-1">
						 <v-tooltip top>
						   <template  v-slot:activator="{ on }">
						     <span v-bind:style="{ width: titleWidth + 'px'  }" class="d-inline-block text-truncate  "  v-on="on">
						       {{strength.name}}
						     </span>
			               </template>
						{{strength.name}}
						</v-tooltip>
						</v-card-title>
						<v-card-subtitle>
							{{strength.solved}}/350 Completed <br/>
							<!-- {{strength.level}} -->
						</v-card-subtitle>
					</div>
				</div>
			</v-card>
		</v-col>
	</v-row>
	<v-row>
		<v-col class="mt-5 font-weight-bold text-truncate text-h5">
				Weaknesses
		</v-col>
	</v-row>
	<v-row>
		<v-col :cols="12"  :sm="6" :md="3" :lg="3" v-for="(weakness) in weaknesses" v-bind:key="weakness.name">
			<v-card id="next" outlined class="weakness" :width="270" :height="100">
				<div class="d-flex flex-no-wrap">
					<v-avatar class="my-4 ml-4" size="imageWidth" tile>
						<v-img :src="require('./../assets/images/drill/' + weakness.name + '.svg')"></v-img>
					</v-avatar>
					<div>
						<v-card-title class="text-caption-1">
						 <v-tooltip top>
						   <template  v-slot:activator="{ on }">
						     <span  v-bind:style="{ width: titleWidth + 'px'  }" class="d-inline-block text-truncate"  v-on="on">
						       {{weakness.name}}
						     </span>
			               </template>
						   {{weakness.name}}
						  </v-tooltip>
						</v-card-title>
						<v-card-subtitle>
							{{weakness.solved}}/350 Completed <br/>
							<!-- {{weakness.level}} -->
						</v-card-subtitle>
					</div>
				</div>
			</v-card>
		</v-col>
	</v-row>

	<!--<v-sheet class="pl-12 pr-5 mt-10 white lighten-5">
		<v-row>
			<v-col class="font-weight-bold text-truncate display-2">
					Code Analysis
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				See insights into your code and tutorials to beat the errors
			</v-col>
		</v-row>
		<v-row>
			<v-col  cols="3" v-for="(analysis) in codeAnalysis" v-bind:key="analysis.name">
				<v-card outlined :width="270" :height="100">
					<div class="d-flex flex-no-wrap">
						<v-avatar class="my-4 ml-4" size="50" tile>
							<v-img :src="require('./../assets/images/codeanalysis/' + analysis.name + '.svg')"></v-img>
						</v-avatar>
						<div>
							<v-card-title>
								<span class="text-truncate text-caption-1" style="max-width: 177px;">
									{{analysis.name}}
								</span>
							</v-card-title>
							<v-card-subtitle>
								{{analysis.level}}
							</v-card-subtitle>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-sheet> -->
</page>
</template>
<script>
import { mapState, mapActions , mapGetters} from "vuex";
import Page from "../components/Page";
import PieChart from "../components/PieChart";
import ProblemList from "../components/ProblemList";

export default {
	data() {
		return {
			titleWidth:0,
		    imageWidth:0,
			submissionStats: {
				labels: ['Easy', 'Medium', 'Hard'],
				datasets: [ {
					data: [50, 40, 30],
					backgroundColor: ["#CEF2F2", "#17D8DA", "#116E6F"],
				} ],
			},
			submissionChartOptions: {
				'legend': {
					position: 'right',
					align: 'middle',
					labels: {
						boxWidth: 10,
					},
				},
				maintainAspectRatio: true,
			},
			companies: [
				{
					name: "Facebook",
					solved: 50,
				},
				{
					name: "Google",
					solved: 30,
				},
				{
					name: "Amazon",
					solved: 40,
				},
				{
					name: "Microsoft",
					solved: 50,
				},
				{
					name: "Apple",
					solved: 20,
				},
			],
			strengths: [
				{
					name: "Binary Tree",
					level: "Expert",
					solved: 50,
				},
				{
					name: "Greedy",
					level: "Expert",
					solved: 30,
				},
				{
					name: "Graph",
					level: "Beginner",
					solved: 10,
				},
				{
					name: "Maths",
					level: "Expert",
					solved: 50,
				},
			],
			weaknesses: [
				{
					name: "Tree",
					level: "Novice",
					solved: 5,
				},
				{
					name: "Geometry",
					level: "Novice",
					solved: 70,
				},
			],
			codeAnalysis: [
				{
					name: "Edge Cases",
					level: "Novice",
				},
				{
					name: "Time Analysis",
					level: "Beginner",
				},
				{
					name: "Library Function",
					level: "Competent",
				},
				{
					name: "Code Modularity",
					level: "Proficient",
				}
			],
		};
	},

	methods: {
		...mapGetters("content", ["getContentType"]),
    ...mapActions("content", ["fetchUserContentPreviews"]),
	  getCardWidth(){
            this.titleWidth=((document.getElementById('next').clientWidth)*3)/5 ;
			this.imageWidth=((document.getElementById('next').clientWidth)*1)/5;
		},

	},
  computed: {
    ...mapState("content", ["userContentPreviews", "fetchUserContentPreviewsStatus"]),
  },
	async mounted() {
    this.fetchUserContentPreviews({"tags": ["types/starter"], "contentTypes": [this.getContentType()['PROBLEM']]});
	this.getCardWidth();
  },
	components: {
		Page,
		PieChart,
		ProblemList,
	}
}
</script>
<style scoped>
.strength {
	border-left-color: #33A79D;
	border-left-width: 10px !important;
}
.weakness {
	border-left-color: #EEEEEE;
	border-left-width: 10px !important;
}
.dashboard_link {
    color: #33A79D;
		text-decoration: none;
};
</style>
